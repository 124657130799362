import { Row } from 'react-bootstrap';

function NotFound() {

    return (
        <Row>
            <h1>404 - Page Not Found</h1>

            <p className='mt-5'>The page you are looking for does not exist.</p>
        </Row>
    );
}

export default NotFound;
