import React, { useEffect, useState } from 'react';
import { Row, Modal, ListGroup, Button, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

/**
 * 個人ごとの独自機能ページ.
 */
function IndividualPage() {
    const { state } = useLocation();
    const { employee } = state;

    const [showGet, setShowGet] = useState(false);
    const [selectedIndividual, setSelectedIndividual] = useState(null);

    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleGetClose = () => setShowGet(false);

    /**
     * 処理実行.
     * 
     * @param {employee.individual} individual 
     */
    const handleGetShow = (individual) => {
        setSelectedIndividual(individual);
        setShowGet(true);
    };

    /**
     * リンクを開く.
     * 
     * @param {string} url 
     */
    const openLink = (url) => {
        window.open(url, '_blank');
    };

    /**
     * データ取得.
     */
    const fetchData = async () => {
        if (!selectedIndividual) return;
        setShowSuccess(false);
        setLoading(true);

        try {
            const response = await fetch(selectedIndividual.url);
            const data = await response.json();
            console.log(data);
        } catch (error) {
            // 現状通信結果は評価しない
            console.error('Error fetching data:', error);
        } finally {
            setShowSuccess(true);
            setLoading(false);
            handleGetClose();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (employee) {
            document.title = `${employee.lastName}${employee.firstName} - 個別業務`;
        }
    }, [employee]);

    return (
        <Row>
            <h1>個別業務</h1>

            {employee.individual.filter(individual => individual.type === 0).length > 0 && (
                <Row className='mt-5 mb-3'>
                    <h2>リンク</h2>
                    <ListGroup className='mt-3' as="ol" numbered>
                        {employee.individual.filter(individual => individual.type === 0).map(individual => (
                            <ListGroup.Item
                                key={individual.id}
                                className='pt-3 pb-3'
                                style={{ cursor: 'pointer' }}
                                rel="noopener noreferrer"
                                onClick={() => openLink(individual.url)}
                            >
                                {individual.name}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Row>
            )}

            {employee.individual.filter(individual => individual.type === 1).length > 0 && (
                <Row className='mt-5 mb-3'>
                    <h2>通信(GET)</h2>
                    <ListGroup className='mt-3' as="ol" numbered>
                        {employee.individual.filter(individual => individual.type === 1).map(individual => (
                            <ListGroup.Item
                                key={individual.id}
                                className='pt-3 pb-3'
                                style={{ cursor: 'pointer' }}
                                rel="noopener noreferrer"
                                onClick={() => handleGetShow(individual)}
                            >
                                {individual.name}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Row>
            )}

            <Modal show={showGet} onHide={handleGetClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedIndividual ? selectedIndividual.name : '通信'}の確認</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>以下の業務に対して処理を行います。<br />
                        {selectedIndividual ? selectedIndividual.name : ''}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleGetClose}>
                        キャンセル
                    </Button>
                    <Button variant="primary" onClick={fetchData} disabled={loading}>
                        {loading ? '送信中...' : '送信'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {showSuccess && <Alert variant="success">通信が完了しました。</Alert>}
        </Row>
    );
}

export default IndividualPage;
