
const Roles = {
    ADMIN: { id: 1, name: "管理者" },
    MEMBER: { id: 2, name: "一般ユーザー" },
    VIEWER: { id: 3, name: "閲覧者" },
    GUEST: { id: 4, name: "ゲスト" },
    NEW_ROLE: { id: 5, name: "新しい役割" }
};

export default Roles;
