import React, { useEffect } from 'react';
import { Row, Alert, ListGroup, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MainCard from '../widgets/MainCard';

function HomePage({ firebaseUser, employee, onLogin, loginError }) {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "QuickHub";
    }, []);

    const requestForms = [
        {
            id: "1FAIpQLSdlsnZMEnKJL9BAhO0uycpHyaPa1roeZnYv-Si-dVFV9Lts8w",
            name: "勤怠連絡",
        },
        {
            id: "1FAIpQLSddwl90DSODAntYWiFeT8KauYV_1qjRU2P_oKAbpYzw5PXMpw",
            name: "健康診断希望日",
        },
        {
            id: "1FAIpQLSffdPe4MTU7dZ-Zj3FNpaBXVJNP4fpJvB5r4-HPzKGokU42gQ",
            name: "書籍購入申請",
        },
        {
            id: "1FAIpQLSf8A1k9HJoH5kv1eS4F0M7t29vxI8d1jg3MTjg1oQdNX1HRkQ",
            name: "趣味資格申請",
        },
        {
            id: "1FAIpQLScWPFm3O0z-d1pY-Rwz0NHNtmKAT9LlGagdVsANXuEl4D17Cg",
            name: "書類提出",
        },
        {
            id: "1FAIpQLScMYA3TGtcWAUHAg3XmOg_jZbjbjp_6oJeDc55-jYZieUjL8w",
            name: "名刺作成依頼",
        },
    ];

    return (
        <>
            {loginError && <Alert variant="danger">{loginError}</Alert>}
            {firebaseUser ? (
                <>
                    <h1>
                        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="logo" width="380" />
                    </h1>

                    <h2 className='mt-5'>日常業務</h2>
                    <Row className='mt-4'>
                        {/* コーポレートは表示しない */}
                        {employee.role < 3 && (
                            <>
                                <MainCard
                                    header="デイレポ"
                                    description="なんでもつぶやこう"
                                    path="dayrepo"
                                    onClick={() => {
                                        navigate('report/dayrepo');
                                    }}
                                />
                                <MainCard
                                    header="ウィーレポ"
                                    description="週報の作成と共有"
                                    path="weerepo"
                                    onClick={() => {
                                        navigate('report/weerepo');
                                    }}
                                />
                            </>
                        )}
                        <MainCard
                            header="スキルシート"
                            description="スキルシート 登録・生成"
                            path="skill"
                            onClick={() => {
                                navigate('skill');
                            }}
                        />
                        <MainCard
                            header="従業員一覧"
                            description="従業員情報を確認"
                            path="employee"
                            onClick={() => {
                                navigate('employee');
                            }}
                        />
                        {employee.individual != null && employee.individual.length > 0 && (
                            <MainCard
                                header="個別業務"
                                description="個人別業務を確認"
                                path="individual"
                                onClick={() => {
                                    navigate("/individual", { state: { employee: employee } });
                                }}
                            />
                        )}
                    </Row>

                    <h2 className='mt-5'>申請</h2>
                    <ListGroup className='mt-3' as="ol" numbered>
                        {requestForms.map(request => (
                            <ListGroup.Item
                                key={request.id}
                                target="_blank"
                                action href={`https://docs.google.com/forms/d/e/${request.id}/viewform`}
                                rel="noopener noreferrer"
                            >
                                {request.name}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>

                    <h2 className='mt-5'>確認</h2>
                    <ListGroup className='mt-3' as="ol" numbered>
                        <ListGroup.Item
                            target="_blank"
                            action href="https://payroll.moneyforward.com/"
                            rel="noopener noreferrer"
                        >
                            給与明細
                        </ListGroup.Item>
                        <ListGroup.Item
                            target="_blank"
                            action href="https://2f45a90d7a1eca3567c3ab9b.smarthr.jp/"
                            rel="noopener noreferrer"
                        >
                            従業員情報
                        </ListGroup.Item>
                    </ListGroup>
                </>
            ) : (
                <div className='text-center'>
                    <p>サービスのご利用にはログインが必要です。</p>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/google_sign_in.png`}
                        onClick={onLogin}
                        alt="Google Sign In"
                        className='image-button mt-3 mb-5'
                        style={{ cursor: 'pointer' }}
                        width='260'
                    />
                </div>
            )}
        </>
    );
}

export default HomePage;
