import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Alert, Dropdown, DropdownButton, Spinner, Modal } from 'react-bootstrap';
import { postDayrepo } from '../../../firebase-config';

/**
 * デイレポ送信画面.
 * 
 * @param {employee} 従業員 
 * @returns 
 */
function DayRepoPage({ employee }) {
    useEffect(() => {
        document.title = "デイレポ";
    }, []);

    const [emoji, setEmoji] = useState({ icon: "😀", value: "smile", score: 5, description: "笑顔" });
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(true);
    const [url, setUrl] = useState('');
    const [showQuick, setShowQuick] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleQuickClose = () => setShowQuick(false);

    const handleQuickShow = () => {
        setShowQuick(true);
    };

    const emojis = [
        // ポジティブな感情
        { icon: "😀", value: "smile", score: 5, description: "笑顔" },
        { icon: "😂", value: "joy", score: 5, description: "大笑い" },
        { icon: "😍", value: "heart_eyes", score: 4, description: "ハートの目" },
        { icon: "🎉", value: "tada", score: 5, description: "お祝い" },
        { icon: "💪", value: "muscle", score: 4, description: "力こぶ" },
        { icon: "💖", value: "heart", score: 4, description: "ハート" },
        { icon: "💯", value: "100", score: 5, description: "100点" },
        { icon: "⭐", value: "star", score: 4, description: "星" },
        { icon: "👍", value: "+1", score: 4, description: "いいね" },
        { icon: "🍺", value: "beer", score: 5, description: "ビール" },

        // ネガティブな感情
        { icon: "😢", value: "cry", score: 2, description: "涙" },
        { icon: "😭", value: "mimi_cry", score: 1, description: "号泣" },
        { icon: "😪", value: "sleepy", score: 2, description: "眠い" },
        { icon: "😡", value: "hd-anger", score: 1, description: "怒り" },
        { icon: "😵", value: "dizzy_face", score: 2, description: "目が回る" },
        { icon: "🙈", value: "see_no_evil", score: 2, description: "見ざる" },
        { icon: "👎", value: "-1", score: 1, description: "ダメ" },

        // 中立的な感情
        { icon: "💬", value: "speech_balloon", score: 3, description: "吹き出し" },
        { icon: "👀", value: "eyes", score: 3, description: "目" },
        { icon: "📅", value: "calendar", score: 3, description: "カレンダー" },
        { icon: "📷", value: "camera", score: 3, description: "カメラ" },
        { icon: "📺", value: "tv", score: 3, description: "テレビ" },
        { icon: "🙏", value: "pray", score: 4, description: "祈り" },
        { icon: "🚆", value: "train", score: 3, description: "電車" },
        { icon: "🤯", value: "exploding_head", score: 4, description: "爆発" },
        { icon: "👋", value: "wave", score: 3, description: "手を振る" },
        { icon: "💰", value: "moneybag", score: 4, description: "お金" },
        { icon: "💨", value: "dash", score: 3, description: "ダッシュ" },
        { icon: "🆘", value: "sos", score: 2, description: "SOS" },
        { icon: "🌧️", value: "rain_cloud", score: 2, description: "雨雲" },
        { icon: "⛄", value: "snowman_without_snow", score: 2, description: "雪だるま" },
        { icon: "🙉", value: "hear_no_evil", score: 2, description: "聞かざる" },
        { icon: "🙊", value: "speak_no_evil", score: 2, description: "言わざる" }
    ];

    const quickOptions = [
        { icon: "😵", value: "dizzy_face", score: 1, description: "忙しい" },
        { icon: "📅", value: "calendar", score: 2, description: "ミーティング多い" },
        { icon: "💬", value: "speech_balloon", score: 3, description: "やや忙しい" },
        { icon: "⭐", value: "star", score: 4, description: "適度な作業量" }
    ];

    const handleQuickSelect = async (option) => {
        await submitDayRepo(option.description, option);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await submitDayRepo(message, emoji);
    };

    const handleMessageChange = (e) => {
        const inputValue = e.target.value;
        const sanitized = inputValue
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .slice(0, 100);
        setMessage(sanitized);
    };

    const handleUrlChange = (e) => {
        const inputValue = e.target.value;
        setUrl(inputValue);
        if (inputValue) {
            validateUrl(inputValue);
        } else {
            setIsValidUrl(true);
        }
    };

    const validateUrl = (url) => {
        try {
            new URL(url);
            setIsValidUrl(true);
        } catch (error) {
            setIsValidUrl(false);
        }
    };

    const submitDayRepo = async (messageContent, emojiOption) => {
        setLoading(true);

        try {
            await postDayrepo(
                employee.id,
                emojiOption,
                messageContent,
                status,
                employee.legechId,
                employee.slackMemberId,
                url
            );
            setShowSuccess(true);
            setShowError(false);
            setMessage("");
            setUrl("");
        } catch (error) {
            setShowSuccess(false);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Row>
            <h1>デイレポ</h1>

            <Form onSubmit={handleSubmit} className='p-4'>
                <Row>
                    <Col xs={6} >
                        <Form.Group controlId="formEmoji" size="lg">
                            <DropdownButton
                                className='w-300'
                                variant="outline-secondary"
                                title={`${emoji.icon} ${emoji.description}`}
                                id="input-group-dropdown-1"
                            >
                                {emojis.map(e => (
                                    <Dropdown.Item key={e.value} onClick={() => setEmoji(e)}>
                                        {e.icon} {e.description}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Form.Group>
                    </Col>
                    <Col xs={6} >
                        <Button onClick={handleQuickShow} style={{ cursor: 'pointer' }} variant="light">
                            Quick
                        </Button>
                    </Col>
                </Row>

                <Form.Group controlId="formStatus">
                    <Form.Check
                        type="switch"
                        label="ステータス反映"
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                        className='mt-2'
                    />
                </Form.Group>

                <Form.Group controlId="formMessage" className='mt-3'>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={message}
                        onChange={handleMessageChange}
                        placeholder="メッセージ"
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formUrl" className='mt-3'>
                    <Form.Control
                        type="text"
                        value={url}
                        onChange={handleUrlChange}
                        placeholder="URL or 画像URL"
                        isInvalid={!isValidUrl}
                    />
                    <Form.Control.Feedback type="invalid">
                        URLが無効です。
                    </Form.Control.Feedback>
                </Form.Group>

                <div className="d-grid gap-2 mt-3">
                    <Button variant="primary" type="submit" size="lg" disabled={loading || !isValidUrl}>
                        {loading ? '送信中...' : '送信'}
                    </Button>

                    {loading && <Spinner animation="border" variant="dark" />}
                </div>
            </Form>

            {showSuccess && <Alert variant="success">送信しました。</Alert>}
            {showError && <Alert variant="danger">送信失敗しました。</Alert>}

            <Modal show={showQuick} onHide={handleQuickClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Quick</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {quickOptions.map(option => (
                        <Button
                            key={option.description}
                            variant="light"
                            onClick={() => handleQuickSelect(option)}
                            className="w-100 mb-2"
                            disabled={loading}
                        >
                            {option.icon} {option.description}
                        </Button>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleQuickClose} disabled={loading}>
                        キャンセル
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>
    );
}

export default DayRepoPage;
