import React from 'react';
import { Navigate } from 'react-router-dom';

function RequireAuth({ firebaseUser, children }) {
    if (!firebaseUser) {
        return <Navigate to="/" replace />;
    }

    return children;
}

export default RequireAuth;
