import React, { useState, useEffect } from 'react';
import { Alert, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

/**
 * スキルシート選択 ページ
 * 
 * @param {firebaseUser} Firebase User
 * @param allUser 全ユーザー 
 * @returns スキルシート選択 ページ
 */
function SkillPage({ firebaseUser, allUser }) {
    useEffect(() => {
        document.title = "スキルシート";
    }, []);

    const navigate = useNavigate();

    const [customName, setCustomName] = useState('');

    const [role] = useState(() => {
        return allUser.find(u => u.id === firebaseUser.uid).role;
    });

    const [user, selectUser] = useState(() => {
        return allUser.find(u => u.id === firebaseUser.uid) || allUser[0];
    });

    const [outputDate, setOutputDate] = useState(() => {
        const today = new Date();
        const formattedDate = today.toISOString().slice(0, 10);
        return formattedDate;
    });

    function getUserFullName(user) {
        if (user.role == 3) {
            return "選択してください";
        }
        console.log(user);
        return user.lastName + " " + user.firstName;
    }

    const handleSubmit = () => {
        if (user.role == 3) {
            return
        }
        navigate('/skill/detail', {
            state: {
                employee: user,
                role: role,
                date: outputDate,
                customName: customName
            }
        });
    };

    return (
        <>
            <h1>スキルシート</h1>

            <Alert key='info' variant='info' className='mt-5'>
                スキルシートを生成します。<br />
                ユーザーを選択して最後に「出力」を押下してください。
            </Alert>

            <Form onSubmit={handleSubmit} className='mt-5 mb-5'>
                <Form.Group controlId="customName" className='mt-3'>
                    <DropdownButton
                        variant="outline-secondary"
                        title={getUserFullName(user)}
                    >
                        {allUser
                            .filter(employee => employee.role < 3)
                            .map(user => (
                                <Dropdown.Item
                                    key={user.id}
                                    onClick={() => selectUser(user)}
                                >
                                    {getUserFullName(user)}
                                </Dropdown.Item>
                            ))}
                    </DropdownButton>
                </Form.Group>


                <Form.Group controlId="customName" className='mt-3'>
                    <Form.Control
                        className='mt-3'
                        value={customName}
                        onChange={e => setCustomName(e.target.value)}
                        placeholder="表示名 - 氏名の変更表示する場合(匿名にする場合はスペース)"
                    />
                </Form.Group>

                <Form.Group controlId="formMessage" className='mt-3'>
                    <Form.Control
                        type='date'
                        placeholder="出力日"
                        onChange={e => setOutputDate(e.target.value)}
                    />
                </Form.Group>

                <div className="d-grid gap-2 mt-3">
                    <Button variant="primary" type="submit" size="lg" onClick={() => handleSubmit()}>
                        出力
                    </Button>
                </div>
            </Form>

            {user.role < 3 && (
                <Button variant='secondary' onClick={() => {
                    navigate('/skill/import');
                }}>
                    スキルインポート
                </Button>
            )}
        </>
    );
}

export default SkillPage;
