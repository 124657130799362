import React, { useEffect, useState } from 'react';
import { Row, Modal, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Roles from '../../roles';
import Table from 'react-bootstrap/Table';
import md5 from 'md5';

/**
 * プロフィールページ.
 */
function ProfilePage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { myEmployee, employee } = state;
    const hash = md5((employee.legechId + "@legech.co.jp").trim().toLowerCase());
    const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?d=identicon`;

    const [showImage, setShowImage] = useState(false);

    const handleImageClose = () => setShowImage(false);
    const handleImageShow = () => setShowImage(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (employee) {
            document.title = `${employee.lastName}${employee.firstName} - プロフィール`;
        }
    }, [employee]);

    const isAdmin = (roleId) => {
        for (const role of Object.values(Roles)) {
            if (role.id === roleId) {
                return role == Roles.ADMIN;
            }
        }
        return false;
    };

    /**
     * 権限番号からアプリケーション権限名を取得.
     * 
     * @param {number} roleId 
     * @returns {string} 権限
     */
    const getRoleName = (roleId) => {
        for (const role of Object.values(Roles)) {
            if (role.id === roleId) {
                return role.name;
            }
        }
        return "UNKNOWN";
    };

    /**
     * 年月日の形にして返す.
     * 
     * @param {number} date 
     * @returns {string} 年月日
     */
    function convertDate(date) {
        const time = new Date(date * 1000);
        return time.getFullYear() + "年" + (time.getMonth() + 1) + "月" + time.getDate() + "日";
    }

    /**
     * 生年月日から年齢を計算して返す.
     * 
     * @param {number} date 
     * @returns {number} 現在の年齢
     * @description
     * 生年月日から現在の年齢を計算するための関数です。与えられた UNIX タイムスタンプを基に、
     * 生年月日と今年の誕生日を計算し、現在の日付と比較して実際の年齢を求めます。
     * 今年の誕生日がまだ来ていない場合、計算された年齢から1を引きます。
     */
    function getAge(date) {
        const birthday = new Date(date * 1000);
        const today = new Date();
        // 今年の誕生日
        const thisYearsBirthday = new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate());
        // 年齢
        var age = today.getFullYear() - birthday.getFullYear();
        if (today < thisYearsBirthday) {
            //今年まだ誕生日が来ていない
            age--;
        }
        return age;
    }

    /**
     * 入社からの年月を返却.
     * 
     * @param {number} joinDate 
     * @returns {string} 入社年月
     */
    function calculateTenure(joinDate) {
        const joinDateObj = new Date(joinDate * 1000);
        const today = new Date();
        let years = today.getFullYear() - joinDateObj.getFullYear();
        let months = today.getMonth() - joinDateObj.getMonth();

        if (today.getDate() < joinDateObj.getDate()) {
            months--;
        }
        if (months < 0) {
            years--;
            months += 12;
        }
        return `${years}年${months}ヶ月`;
    }

    /**
     * 55制度の対象者かどうかを判断し、現在の対象期間を計算する.
     * 
     * @param {number} joinDate 
     * @returns {object} 55制度の対象期間の開始日と終了日、および現在の対象期間かどうか
     */
    function getCurrent55Period(joinDate) {
        const joinDateObj = new Date(joinDate * 1000);
        const today = new Date();

        // 入社からの経過年数を計算
        const tenureYears = Math.floor((today - joinDateObj) / (365.25 * 24 * 60 * 60 * 1000));

        // 5年未満の場合、次の5年目を計算
        if (tenureYears < 5) {
            const eligibleStartDate = new Date(joinDateObj);
            eligibleStartDate.setFullYear(joinDateObj.getFullYear() + 5);

            const eligibleEndDate = new Date(eligibleStartDate);
            eligibleEndDate.setFullYear(eligibleStartDate.getFullYear() + 1);

            return {
                isEligible: false,
                eligibleStartDate,
                eligibleEndDate,
                tenureYears
            };
        }

        // 5年目が対象期間の開始年
        const nextEligibleYear = Math.floor(tenureYears / 5) * 5;

        // 対象期間の開始日と終了日を計算
        const eligibleStartDate = new Date(joinDateObj);
        eligibleStartDate.setFullYear(joinDateObj.getFullYear() + nextEligibleYear);

        const eligibleEndDate = new Date(eligibleStartDate);
        eligibleEndDate.setFullYear(eligibleEndDate.getFullYear() + 1);

        // 現在の期間が対象期間内かどうかを判定
        const isEligible = (today >= eligibleStartDate && today < eligibleEndDate);

        // 対象外の場合、次の対象期間を計算
        if (!isEligible && tenureYears >= 5) {
            eligibleStartDate.setFullYear(eligibleStartDate.getFullYear() + 5);
            eligibleEndDate.setFullYear(eligibleEndDate.getFullYear() + 5);
        }

        return {
            isEligible,
            eligibleStartDate,
            eligibleEndDate,
            tenureYears
        };
    }

    const { isEligible, eligibleStartDate, eligibleEndDate, tenureYears } = getCurrent55Period(employee.join['_seconds']);

    return (
        <Row >

            <h1>プロフィール</h1>
            <Table striped bordered hover className='mt-5'>

                <thead>
                    <tr>
                        <th scope="col">名称</th>
                        <th scope="col">内容</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>アイコン</td>
                        <td>
                            <img
                                src={`${gravatarUrl}`}
                                alt='プロフィール画像'
                                className='rounded-3'
                                style={{ cursor: 'pointer' }}
                                width='52'
                                onClick={handleImageShow}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>氏名</td>
                        <td>

                            {employee.introduction ? (
                                <a href={employee.introduction} target="_blank" rel="noopener noreferrer">
                                    {employee.lastName + " " + employee.firstName}
                                </a>
                            ) : (
                                employee.lastName + " " + employee.firstName
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td>生年月日</td>
                        <td>
                            {convertDate(employee.birthday['_seconds'])}
                            ({getAge(employee.birthday['_seconds'])}歳)
                        </td>
                    </tr>

                    <tr>
                        <td>最寄り駅</td>
                        <td>{employee.line} {employee.station}</td>
                    </tr>

                    <tr>
                        <td>従業員番号</td>
                        <td>{employee.companyId}</td>
                    </tr>

                    <tr>
                        <td>入社日</td>
                        <td>
                            {convertDate(employee.join['_seconds'])} ({calculateTenure(employee.join['_seconds'])})
                        </td>
                    </tr>

                    <tr>
                        <td>55制度</td>
                        <td>
                            {isAdmin(employee.role) ? (
                                '役員 対象外'
                            ) : isEligible ? (
                                `対象期間中: ${convertDate(eligibleStartDate.getTime() / 1000)} - ${convertDate(eligibleEndDate.getTime() / 1000)}`
                            ) : (
                                `次の対象期間: ${convertDate(eligibleStartDate.getTime() / 1000)} - ${convertDate(eligibleEndDate.getTime() / 1000)}`
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td>メールアドレス</td>
                        <td>{employee.legechId}@legech.co.jp</td>
                    </tr>

                    <tr>
                        <td>Firebase ID</td>
                        <td>{employee.id}</td>
                    </tr>

                    <tr>
                        <td>Slack ID</td>

                        <td>
                            <a href={"https://legech.slack.com/team/" + employee.slackMemberId} target="_blank" rel="noopener noreferrer">
                                {employee.slackMemberId}
                            </a>
                        </td>
                    </tr>

                    <tr>
                        <td>Webhook</td>
                        <td>{employee.webhook}</td>
                    </tr>

                    <tr>
                        <td>QuickHub 権限</td>
                        <td>{getRoleName(employee.role)}</td>
                    </tr>

                    {isAdmin(myEmployee.role) && (
                        <tr>
                            <td>ウィーレポ アーカイブ</td>
                            <td>
                                <Button onClick={() => {
                                    navigate('/report/weerepo/archives', { state: { myEmployee, employee } });
                                }}
                                    style={{ cursor: 'pointer' }} variant="light">
                                    アーカイブ
                                </Button>
                            </td>
                        </tr>
                    )}

                </tbody>
            </Table>

            <Modal show={showImage} aria-labelledby="example-modal-sizes-title-sm" onHide={handleImageClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{employee.lastName + " " + employee.firstName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <img
                            src={`${gravatarUrl}&s=350`}
                            alt='プロフィール画像'
                            className='rounded-3'
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleImageClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>
    );
}

export default ProfilePage;
