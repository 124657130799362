import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import md5 from 'md5';

/**
 * 従業員一覧.
 * 
 * @param {User} allUser 
 * @returns 従業員一覧UI
 */
function EmployeePage({ myEmployee, allUser }) {
    useEffect(() => {
        document.title = "従業員一覧";
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const handleRowClick = (employee) => {
        navigate(`/profile`, { state: { myEmployee, employee } });
    };

    function getGravatarUrl(legechId) {
        const email = legechId + "@legech.co.jp";
        const hash = md5(email.trim().toLowerCase());
        return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
    }

    return (
        <Row>
            <h1>従業員一覧</h1>

            <Table striped bordered hover className='mt-5'>
                <thead>
                    <tr>
                        <th scope="col" width='200'>従業員番号</th>
                        <th scope="col">氏名</th>
                    </tr>
                </thead>
                <tbody>
                    {allUser.length > 0 ? (
                        allUser
                            .filter(employee => employee.role !== 3)
                            .map(employee => (
                                <tr key={employee.id}>
                                    <td>{employee.companyId}</td>
                                    <td key={employee.id} onClick={() => handleRowClick(employee)} style={{ cursor: 'pointer' }}>
                                        <img
                                            src={`${getGravatarUrl(employee.legechId)}`}
                                            alt='プロフィール画像'
                                            className='rounded-3'
                                            width='32'
                                        />
                                        <span className='ml-5'>
                                            &nbsp;{employee.lastName} {employee.firstName}
                                        </span>
                                    </td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td colSpan="2">データが存在しません。</td>
                        </tr>
                    )}
                </tbody>


            </Table>
        </Row>
    );
}

export default EmployeePage;
