import React, { useState, useEffect } from 'react';
import { Row, Col, Form, InputGroup, Button, Alert, Dropdown, DropdownButton, Spinner, FormControl } from 'react-bootstrap';
import { fetchWeerepo, postWeerepo } from '../../../firebase-config';
import { useNavigate } from 'react-router-dom';

/**
 * ウィーレポ送信画面.
 * 
 * @param {employee} 従業員 
 * @returns 
 */
function WeeRepoPage({ employee }) {
    useEffect(() => {
        document.title = "ウィーレポ";
    }, []);
    const navigate = useNavigate();

    const [workEmoji, setWorkEmoji] = useState('relieved');
    const [mentalEmoji, setMentalEmoji] = useState('smile');

    const [works, setWorks] = useState(['']);
    const [insideWorks, setInsideWorks] = useState(['']);

    const [content, setContent] = useState('');

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [getLoading, setGetLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const workEmojis = [
        { label: "😌", value: "relieved", state: "落ち着いている", score: 5 },
        { label: "🙂", value: "slightly_smiling_face", state: "やや落ち着いている", score: 4 },
        { label: "🙁", value: "frowning", state: "普通", score: 3 },
        { label: "😔", value: "sad", state: "やや忙しい", score: 2 },
        { label: "🤢", value: "disgust", state: "かなり忙しい", score: 1 },
    ];

    const mentalEmojis = [
        { label: "😆", value: "laughing", state: "非常に良い", score: 5 },
        { label: "😀", value: "smile", state: "良い", score: 4 },
        { label: "🙂", value: "slightly_smiling_face", state: "少し良い", score: 3.5 },
        { label: "🙁", value: "frowning", state: "普通", score: 3 },
        { label: "😟", value: "worried", state: "少し悪い", score: 2.5 },
        { label: "😩", value: "tired", state: "悪い", score: 2 },
        { label: "🤮", value: "face_vomiting", state: "とても悪い", score: 1 }
    ];

    const addWork = () => {
        setWorks([...works, '']);
    };

    const removeWork = (index) => {
        setWorks(works.filter((_, i) => i !== index));
    };

    const addInsideWork = () => {
        setInsideWorks([...insideWorks, '']);
    };

    const removeInsideWork = (index) => {
        setInsideWorks(insideWorks.filter((_, i) => i !== index));
    };

    const handlePrevData = async () => {
        setGetLoading(true);

        try {
            const list = await fetchWeerepo(employee.id, 1, 0);
            const data = list[0].data;

            if (data) {
                const foundWorkEmoji = workEmojis.find(e => e.value === data.workEmoji.value);
                const foundMentalEmoji = mentalEmojis.find(e => e.value === data.mentalEmoji.value);

                setWorkEmoji(foundWorkEmoji ? foundWorkEmoji.value : workEmojis[0].value);
                setMentalEmoji(foundMentalEmoji ? foundMentalEmoji.value : mentalEmojis[0].value);
                setWorks(data.works);
                setInsideWorks(data.insideWorks);
                setContent(data.content ? data.content.replace(/\\n/g, '\n') : '');
            }
        } catch (error) {
            console.error("Error fetching previous data:", error);
        } finally {
            setGetLoading(false);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const selectedWorkEmoji = workEmojis.find(emoji => emoji.value === workEmoji);
            const selectedMentalEmoji = mentalEmojis.find(emoji => emoji.value === mentalEmoji);

            await postWeerepo(
                employee.id,
                employee.slackMemberId,
                employee.legechId,
                selectedWorkEmoji,
                selectedMentalEmoji,
                works,
                insideWorks,
                content
            );
            setShowSuccess(true);
            setShowError(false);
            setContent("");
        } catch (error) {
            console.error("Error submitting data:", error);
            setShowSuccess(false);
            setShowError(true);
            setErrorMessage(error.response?.data?.details || 'Unknown error occurred');
        } finally {
            setLoading(false);
        }
    };


    function getViewLabel(emojiJson) {
        return emojiJson.label + " " + emojiJson.state;
    }

    return (
        <Row>
            <Row className='mt-4'>
                <Col xs={6} >
                    <h1>ウィーレポ</h1>
                </Col>
                <Col xs={3} className='text-end'>
                    <Button onClick={() => handlePrevData()} style={{ cursor: 'pointer' }} variant="light">
                        {getLoading ? '取得中...' : '前回分取得'}
                    </Button>

                    {getLoading && <div><Spinner animation="border" variant="dark" /></div>}
                </Col>
                <Col xs={3} className='text-end'>
                    <Button onClick={() => {
                        navigate('/report/weerepo/archives', { state: { myEmployee: employee, employee: employee } });
                    }}
                        style={{ cursor: 'pointer' }} variant="light">
                        アーカイブ
                    </Button>

                </Col>
            </Row>

            <Form onSubmit={handleSubmit} className='mb-3'>
                <Row className='mt-4'>
                    <Col xs={6} >
                        <Form.Group controlId="formWorkEmoji" className="mb-3">
                            <Form.Label>
                                業務量
                            </Form.Label>
                            <DropdownButton
                                variant="outline-secondary"
                                title={getViewLabel(workEmojis.find(e => e.value === workEmoji))}
                            >
                                {workEmojis.map(e => (
                                    <Dropdown.Item
                                        key={e.value} onClick={() => setWorkEmoji(e.value)}
                                    >
                                        {getViewLabel(e)}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Form.Group>

                    </Col>
                    <Col xs={6} >

                        <Form.Group controlId="formMentalEmoji" size="lg">
                            <Form.Label>
                                メンタル
                            </Form.Label>
                            <DropdownButton
                                variant="outline-secondary"
                                title={getViewLabel(mentalEmojis.find(e => e.value === mentalEmoji))}
                            >
                                {mentalEmojis.map(e => (
                                    <Dropdown.Item
                                        key={e.value} onClick={() => setMentalEmoji(e.value)}
                                    >
                                        {getViewLabel(e)}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Form.Group>

                    </Col>
                </Row>

                <Form.Group controlId="formMessage" className='mt-3'>
                    <Form.Label>
                        現場
                    </Form.Label>

                    {works.map((work, index) => (
                        <InputGroup className="mb-3" key={index}>
                            <FormControl
                                value={work}
                                onChange={e =>
                                    setWorks([...works.slice(0, index), e.target.value, ...works.slice(index + 1)])
                                }
                            />
                            <Button variant="outline-danger" onClick={() => removeWork(index)}>削除</Button>
                        </InputGroup>
                    ))}
                    <div className="gap-2 mt-3">
                        <Button onClick={addWork} variant="secondary">
                            追加
                        </Button>
                    </div>
                </Form.Group>


                <Form.Group controlId="formMessage" className='mt-3'>
                    <Form.Label>
                        自社
                    </Form.Label>

                    {insideWorks.map((insideWork, index) => (
                        <InputGroup className="mb-3" key={index}>
                            <FormControl
                                value={insideWork}
                                onChange={e =>
                                    setInsideWorks([...insideWorks.slice(0, index), e.target.value, ...insideWorks.slice(index + 1)])
                                }
                            />
                            <Button variant="outline-danger" onClick={() => removeInsideWork(index)}>削除</Button>
                        </InputGroup>
                    ))}
                    <div className="gap-2 mt-3">
                        <Button onClick={addInsideWork} variant="secondary">
                            追加
                        </Button>
                    </div>
                </Form.Group>

                <Form.Group controlId="formMessage" className='mt-3'>
                    <Form.Control
                        as="textarea"
                        rows={8}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder="連絡事項"
                        required
                    />
                </Form.Group>

                <div className="d-grid gap-2 mt-5">
                    <Button variant="primary" type="submit" size="lg" disabled={loading}>
                        {loading ? '送信中...' : '送信'}
                    </Button>

                    {loading && <Spinner animation="border" variant="dark" />}
                </div>
            </Form>

            {showSuccess && <Alert variant="success">送信しました。</Alert>}
            {showError && <Alert variant="danger">送信失敗しました。{errorMessage}</Alert>}
        </Row>
    );
}

export default WeeRepoPage;
