import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Header({ firebaseUser, employee, onLogout }) {
  const navigate = useNavigate();

  const goToProfile = () => {
    if (employee) {
      console.log(employee);
      navigate("/profile", { state: { myEmployee: employee, employee: employee } });
    } else {
      console.error("No user data available to pass to profile page.");
    }
  };

  return (
    <Navbar
      expand="lg"
      className="sb-topnav navbar navbar-expand navbar-dark bg-dark mb-5"
    >
      <Container>
        {firebaseUser ? (
          <>
            <Navbar.Brand as={Link} to="/">
              <img
                src={`${process.env.PUBLIC_URL}/images/icon.png`}
                alt="Legech"
                width="32"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title="ツール">
                  <NavDropdown.Item href="https://calendar.google.com/">
                    カレンダー
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://mail.google.com/">
                    メール
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://drive.google.com/">
                    ドライブ
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="https://legech.kibe.la/">
                    Kibela
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://legech.slack.com/">
                    Slack
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://github.com/Legech">
                    Github
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <NavDropdown title={`${firebaseUser.displayName}`}>
                  <NavDropdown.Item to="/profile" onClick={goToProfile}>
                    プロフィール
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onLogout}>
                    ログアウト
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        ) : null}
      </Container>
    </Navbar>
  );
}

export default Header;
