import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { updateSkill, registerSkill } from '../../../firebase-config';

/**
 * スキル編集 ページ
 * 
 * @returns スキル編集 UI
 */
function SkillFormPage() {
    const { state } = useLocation();
    const { employee, skill: initialSkill } = state;

    const [skillId, setSkillId] = useState('');
    const [isEdit, setEdit] = useState(initialSkill != null);
    const [loading, setLoading] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const [skill, setSkill] = useState({
        firebaseId: employee.id,
        company: '',
        start: '',
        end: '',
        overview: '',
        detail: '',
        technology: '',
        lang: '',
        role: '',
        development_count: 0,
        total_count: 0,
        highlight: false,
        visible: true
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        if (initialSkill) {
            setSkillId(initialSkill.id);
            const startDate = new Date(initialSkill.start._seconds * 1000).toISOString().slice(0, 10);
            let endDate;
            if (initialSkill.end !== "") {
                endDate = new Date(initialSkill.end._seconds * 1000).toISOString().slice(0, 10);
            } else {
                endDate = "";
            }

            setSkill({
                ...initialSkill,
                start: startDate,
                end: endDate
            });
        }
    }, [initialSkill]);

    /**
     * 編集・登録.
     * 
     * @param {*} event 
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowError(false);
        setShowSuccess(false);
        setLoading(true);

        if (isEdit) {
            // 編集処理
            try {
                await updateSkill(skillId, employee.id, skill);
                setShowSuccess(true);
            } catch (error) {
                setShowError(true);
            }
        } else {
            const newSkillId = await registerSkill(employee.id, skill);
            if (newSkillId) {
                setSkillId(newSkillId);
                setEdit(true);
                setShowSuccess(true);
            } else {
                setShowError(true);
            }
        }
        setLoading(false);
    };

    return (
        <Container>
            <h1>{employee.lastName} {employee.firstName} - 経歴の{isEdit ? '更新' : '追加'}</h1>
            <Form onSubmit={handleSubmit} className='mt-5'>
                <Form.Group>

                    <Form.Group controlId="company">
                        <Form.Label className='mt-3'>会社名</Form.Label>
                        <Form.Control
                            type="text"
                            value={skill.company}
                            placeholder="会社名"
                            onChange={e => setSkill({ ...skill, company: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="start">
                        <Form.Label className='mt-3'>※開始日</Form.Label>
                        <Form.Control
                            type='date'
                            value={skill.start}
                            onChange={e => setSkill({ ...skill, start: e.target.value })}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="end">
                        <Form.Label className='mt-3'>終了日</Form.Label>
                        <Form.Control
                            type='date'
                            value={skill.end}
                            onChange={e => setSkill({ ...skill, end: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="project">
                        <Form.Label className='mt-3'>プロジェクト内容</Form.Label>
                        <Form.Control
                            type="text"
                            value={skill.overview}
                            placeholder="プロジェクト内容"
                            onChange={e => setSkill({ ...skill, overview: e.target.value })}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="detail">
                        <Form.Label className='mt-3'>プロジェクト詳細</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="8"
                            value={skill.detail}
                            placeholder="プロジェクト詳細"
                            onChange={e => setSkill({ ...skill, detail: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="technorogy">
                        <Form.Label className='mt-3'>メイン技術</Form.Label>
                        <Form.Control
                            type="text"
                            value={skill.technology}
                            placeholder="例)Android,iOS等"
                            onChange={e => setSkill({ ...skill, technology: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="lang">
                        <Form.Label className='mt-3'>言語</Form.Label>
                        <Form.Control
                            type="text"
                            value={skill.lang}
                            placeholder="言語"
                            onChange={e => setSkill({ ...skill, lang: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="role">
                        <Form.Label className='mt-3'>役割</Form.Label>
                        <Form.Control
                            type="text"
                            value={skill.role}
                            placeholder="役割"
                            onChange={e => setSkill({ ...skill, role: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="development_count">
                        <Form.Label className='mt-3'>開発者人数</Form.Label>
                        <Form.Control
                            type="number"
                            value={skill.development_count}
                            placeholder="開発者人数"
                            onChange={e => setSkill({ ...skill, development_count: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="total_count">
                        <Form.Label className='mt-3'>プロジェクト人数</Form.Label>
                        <Form.Control
                            type="number"
                            value={skill.total_count}
                            placeholder="プロジェクト人数"
                            onChange={e => setSkill({ ...skill, total_count: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Check
                        type="switch"
                        label="ハイライト"
                        checked={skill.highlight}
                        onChange={(e) => setSkill({ ...skill, highlight: e.target.checked })}
                        className='mt-3'
                    />

                    <Form.Check
                        type="switch"
                        label="スキルシート表示"
                        checked={skill.visible}
                        onChange={(e) => setSkill({ ...skill, visible: e.target.checked })}
                        className='mt-3'
                    />

                    <div className="d-grid gap-2 mt-5 mb-3">
                        <Button variant="primary" type="submit" size="lg" >
                            {isEdit ? '更新' : '追加'}
                        </Button>
                        {loading && <Spinner animation="border" variant="dark" />}
                    </div>

                    {showSuccess && <Alert variant="success">スキル更新完了しました。{skillId}</Alert>}
                    {showError && <Alert variant="danger">登録失敗しました。</Alert>}
                </Form.Group>
            </Form>
        </Container>
    );
}

export default SkillFormPage;
