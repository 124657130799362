import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * メインで使用するカード.
 * 
 * @param {string} param0 
 * @returns 
 */
function MainCard({ header, path, onClick, description }) {

  return (
    <Col xs={12} sm={8} md={6} lg={3} className='mb-4'>
      <Card onClick={onClick} style={{ cursor: 'pointer' }}>
        <Card.Header as="h5">{header}</Card.Header>
        <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/images/card/` + path + '.png'} />
        <Card.Body>
          <Card.Text>
            {description}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>

  );
}

export default MainCard;