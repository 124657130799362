import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { importSkill } from '../../../firebase-config';

/**
 * スキルインポート ページ
 * 
 * @returns スキルインポート UI
 */
function SkillImportPage() {
    useEffect(() => {
        document.title = "スキルインポート";
    }, []);

    const [loading, setLoading] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const [importJson, setJson] = useState('');

    /**
     * 登録.
     * 
     * @param {*} event 
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowError(false);
        setLoading(true);

        try {
            await importSkill(importJson);
            setShowSuccess(true);
            setShowError(false);
        } catch (error) {
            setShowSuccess(false);
            setShowError(true);
        }

        setLoading(false);
    };

    return (
        <Container>
            <h1>スキルインポート</h1>

            <Alert key='danger' variant='danger' className='mt-5'>
                誤った形式でインポートを行うとデータベースを破壊してしまう可能性があります。<br />
                現状のインポート形式を確認の上、ご利用ください。
            </Alert>

            <Form onSubmit={handleSubmit} className='mt-5'>
                <Form.Group>

                    <Form.Group controlId="detail">
                        <Form.Control
                            as="textarea"
                            rows="15"
                            placeholder="インポート用JSON"
                            value={importJson}
                            onChange={e => setJson(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <div className="d-grid gap-2 mt-5 mb-3">
                        <Button variant="primary" type="submit" size="lg" >
                            インポート
                        </Button>
                        {loading && <Spinner animation="border" variant="dark" />}
                    </div>

                    {showSuccess && <Alert variant="success">スキルインポートが完了しました。</Alert>}
                    {showError && <Alert variant="danger">登録失敗しました。</Alert>}
                </Form.Group>
            </Form>
        </Container>
    );
}

export default SkillImportPage;
