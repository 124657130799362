import React from "react";
import { Row } from "react-bootstrap";
import packageJson from '../../../package.json';

/**
 * 指定した日付から現在の日付までの経過日数と年数を計算する関数
 * @param {string} dateString - 計算の開始日を表す文字列 (例: '2016-12-15')
 * @returns {object} - 経過日数と経過年数を含むオブジェクト
 */
/**
 * 指定した日付から現在の日付までの経過年数と残りの日数を計算する関数
 * @param {string} dateString - 計算の開始日を表す文字列 (例: '2016-12-15')
 * @returns {object} - 経過年数と残りの日数を含むオブジェクト
 */
function calculateTimeSince(dateString) {
  // 指定した日付を設定
  const startDate = new Date(dateString);

  // 現在の日付を取得
  const currentDate = new Date();

  // 両日付の時間の差をミリ秒単位で計算
  const timeDifference = currentDate - startDate;

  // ミリ秒を日に変換
  const totalDaysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // 経過年数を計算
  const yearsDifference = currentDate.getFullYear() - startDate.getFullYear();
  const hasPassedBirthday = (
    currentDate.getMonth() > startDate.getMonth() ||
    (currentDate.getMonth() === startDate.getMonth() && currentDate.getDate() >= startDate.getDate())
  );
  const actualYearsDifference = hasPassedBirthday ? yearsDifference : yearsDifference - 1;

  // 経過年数のミリ秒を計算
  const yearsInMilliseconds = actualYearsDifference * 365.25 * 24 * 60 * 60 * 1000; // うるう年を考慮

  // 経過年数のミリ秒を引いて残りの日数を計算
  const remainingDays = Math.floor((timeDifference - yearsInMilliseconds) / (1000 * 60 * 60 * 24));

  return {
    totalDays: totalDaysDifference,
    years: actualYearsDifference,
    days: remainingDays
  };
}

function Footer({ firebaseUser }) {
  const startDate = '2016-12-15';
  // 経過日数と経過年数を計算
  const timeSince = calculateTimeSince(startDate);

  return (
    <footer className="page-footer font-small text-light bg-dark mt-5 pt-5">
      {firebaseUser ? (
        <div className="text-center">
          <Row>
            <div className="col-md-4 mb-md-0">
              <h5 className="text-uppercase">会社情報</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="https://legech.co.jp/">ホームページ</a>
                </li>
                <li>
                  <a href="https://legech.co.jp/company/">会社概要</a>
                </li>
                <li>
                  <a href="https://legech.kibe.la/notes/9">会社概要(外部非公開)</a>
                </li>
                <li>
                  <a href="https://legech.co.jp/company/mvv/">MVV</a>
                </li>
                <li>
                  <a href="https://legech.co.jp/company/history/">会社沿革</a>
                </li>
                <li>
                  <a href="https://legech.co.jp/recruit/benefits/">福利厚生</a>
                </li>
              </ul>
            </div>

            <div className="col-md-4 mb-md-0">
              <h5 className="text-uppercase">ルール</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="https://legech.kibe.la/notes/209">休暇連絡</a>
                </li>
                <li>
                  <a href="https://legech.kibe.la/notes/217">ウィーレポ</a>
                </li>
              </ul>
            </div>

            <div className="col-md-4 mb-md-0">
              <h5 className="text-uppercase">規則</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="https://drive.google.com/drive/folders/1ypiQoXyvcKASBXwMxLIEGeRUx0kK-Y6_">
                    就業規則
                  </a>
                </li>
                <li>
                  <a href="https://legech.kibe.la/notes/5308">Happy賞</a>
                </li>
                <li>
                  <a href="https://legech.kibe.la/notes/6890">出張</a>
                </li>
              </ul>
            </div>
          </Row>

          <div className="text-center mt-5 mb-2">
            <p>設立から {timeSince.totalDays} 日 ( {timeSince.years} 年{timeSince.days} 日 ）</p>
            <p>Version: {packageJson.version}</p>
          </div>

          <div className="footer-copyright text-center text-bottom py-3 mb-2">
            ©2016 - {new Date().getFullYear().toString()}{" "}
            <a href="https://legech.co.jp/">
              <img
                src={`${process.env.PUBLIC_URL}/images/logo_white.png`}
                alt="Legech"
                width="80"
              />
            </a>
          </div>
        </div>
      ) : null}
    </footer>
  );
}

export default Footer;
