import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Container, Spinner, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { updateSkillOverview } from '../../../firebase-config';

/**
 * スキル概要編集ページ.
 * @returns スキル概要UI
 */
function SkillOverviewEditPage() {
    const { state } = useLocation();
    const { employee, overview } = state;

    const [skills, setSkills] = useState(overview.skill || []);
    const [languages, setLanguages] = useState(overview.language || []);
    const [description, setDescription] = useState(overview.description || '');
    const [loading, setLoading] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (employee) {
            document.title = `${employee.lastName} ${employee.firstName} - スキル概要編集`;
        }
    }, [employee]);

    function escapeDescription(description) {
        return description.replace(/\\n/g, '\n');
    }

    const addSkill = () => {
        setSkills([...skills, '']);
    };

    const removeSkill = (index) => {
        setSkills(skills.filter((_, i) => i !== index));
    };

    const addLanguage = () => {
        setLanguages([...languages, '']);
    };

    const removeLanguage = (index) => {
        setLanguages(languages.filter((_, i) => i !== index));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await updateSkillOverview(employee.id, description, skills, languages);
            setShowSuccess(true);
            setShowError(false);
        } catch (error) {
            setShowSuccess(false);
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <h1>{employee.lastName} {employee.firstName} - スキル概要編集</h1>
            <Form className='mt-5' onSubmit={handleSubmit}>
                <h3>経歴概要</h3>

                <Form.Group controlId="description">
                    <FormControl
                        as="textarea"
                        rows="8"
                        value={escapeDescription(description)}
                        onChange={e => setDescription(e.target.value)}
                    />
                </Form.Group>

                <h3 className='mt-5'>スキル</h3>
                {skills.map((skill, index) => (
                    <InputGroup className="mb-3" key={index}>
                        <FormControl
                            value={skill}
                            onChange={e => setSkills([...skills.slice(0, index), e.target.value, ...skills.slice(index + 1)])}
                        />
                        <Button variant="outline-danger" onClick={() => removeSkill(index)}>削除</Button>
                    </InputGroup>
                ))}
                <Button onClick={addSkill} variant="light">スキルを追加</Button>

                <h3 className='mt-5'>言語</h3>
                {languages.map((language, index) => (
                    <InputGroup className="mb-3" key={index}>
                        <FormControl
                            value={language}
                            onChange={e => setLanguages([...languages.slice(0, index), e.target.value, ...languages.slice(index + 1)])}
                        />
                        <Button variant="outline-danger" onClick={() => removeLanguage(index)}>削除</Button>
                    </InputGroup>
                ))}
                <Button onClick={addLanguage} variant="light">言語を追加</Button>

                <div className="d-grid gap-2 mt-5 mb-3">
                    <Button variant="primary" type="submit" size="lg" disabled={loading}>
                        {loading ? '更新中...' : '更新'}
                    </Button>

                    {loading && <Spinner animation="border" variant="dark" />}
                </div>
                {showSuccess && <Alert variant="success">更新が完了しました。</Alert>}
                {showError && <Alert variant="danger">更新失敗しました。</Alert>}
            </Form>
        </Container>
    );
}

export default SkillOverviewEditPage;
